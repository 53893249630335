import Constitution from '../../static/icons/constitution-bold.svg';
import Quiz from '../../static/icons/quiz-bold.svg';
import { Link } from 'gatsby';
import { Grid, Flex, Box } from '@3pc/layout-components-react';
import Search from '../../static/icons/loupe-bold.svg';
import Books from '../../static/icons/books-bold.svg';
import InfoTap from '../../static/icons/info-tap-bold.svg';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { devices } from '../styles/breakpoints';
import React, { useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import useStaticLogos from '../hooks/use-logos';
import useWindowsHeight from '../hooks/use-windowheight';

const LogoScreen = styled.div`
  /* The animation code */
  @keyframes fadeAway {
    0% {
      opacity: 100%;
      transform: translateY(0);
      top: 0;
      left: 0;
      width: 100%;
      height: ${(props) =>
        props.windowHeight ? props.windowHeight + 'px' : '100vh'};
    }

    50% {
      opacity: 100%;
      transform: translateY(0%);
    }

    75% {
      opacity: 75%;
    }

    85% {
      opacity: 0%;
    }

    99% {
      top: 0;
      left: 0;
      width: 100%;
      height: ${(props) =>
        props.windowHeight ? props.windowHeight + 'px' : '100vh'};
    }

    100% {
      opacity: 0%;
      display: none;
      top: -1000px;
      left: -1000px;
      width: 0%;
      height: 0vh;
      visibility: hidden;
    }
  }

  /* The element to apply the animation to */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  z-index: 1000;
  -webkit-animation: fadeAway 2500ms ease-in;
  -moz-animation: fadeAway 2500ms ease-in;
  -o-animation: fadeAway 2500ms ease-in;
  animation: fadeAway 2500ms ease-in;
  animation-fill-mode: forwards;
`;

const LogoContainer = styled.div`
  width: 140px;

  & div[data-gatsby-image-wrapper] {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${devices.mobileMin} {
    width: 230px;
  }

  &.logo-container-small {
    width: 105px;

    @media ${devices.mobileMin} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;

      & div[data-gatsby-image-wrapper]:first-of-type {
        margin-bottom: 0px;
        margin-right: 30px;
        max-width: 165px;
        border-width: 5px;
      }

      & div[data-gatsby-image-wrapper] {
        flex: 1;
        height: 45px;
        width: auto;
        max-width: 125px;
      }
    }
  }
`;

const StartPageWrapper = styled.div`
  /* The animation code */
  @keyframes fadeInBefore {
    0% {
      left: calc(1800px + 85%);
      top: -2000px;
    }

    100% {
      left: calc(-900px + 85%);
      top: -2750px;
    }

    @media ${devices.mobileMin} {
      0% {
        left: calc(1800px + 85%);
        top: -1000px;
      }

      100% {
        left: calc(-900px + 85%);
        top: -2390px;
      }
    }
  }

  @keyframes fadeInAfter {
    0% {
      left: -80%;
      top: -50%;
    }

    100% {
      left: 10%;
      top: -150%;
    }

    @media ${devices.mobileMin} {
      0% {
        left: calc(1800px + 85%);
        top: -200px;
      }

      100% {
        left: calc(-900px + 85%);
        top: -1350px;
      }
    }
  }

  position: relative;
  background: rgb(205, 221, 236);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    display: block;
    background: linear-gradient(
      117deg,
      rgba(0, 83, 159, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 1280px;
    height: 3800px;
    z-index: 1;
    transform: rotateZ(25deg);

    -webkit-animation: fadeInBefore 1s ease-in;
    -moz-animation: fadeInBefore 1s ease-in;
    -o-animation: fadeInBefore 1s ease-in;
    animation: fadeInBefore 1s ease-in;
    animation-fill-mode: forwards;
    animation-delay: ${(props) => (props.isInitialLoad ? '2s' : '0s')};

    @media ${devices.mobileMin} {
      transform: rotateZ(56deg) translateX(28%);
      background: linear-gradient(
        200deg,
        rgba(0, 83, 159, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    background: linear-gradient(
      65deg,
      rgba(0, 83, 159, 1) 50%,
      rgba(255, 255, 255, 0) 85%
    );
    width: 2400px;
    height: 1200px;
    z-index: 2;
    transform: rotateZ(25deg) translateX(-50%) translateY(100vh);

    -webkit-animation: fadeInAfter 1s ease-in;
    -moz-animation: fadeInAfter 1s ease-in;
    -o-animation: fadeInAfter 1s ease-in;
    animation: fadeInAfter 1s ease-in;
    animation-fill-mode: forwards;
    animation-delay: ${(props) => (props.isInitialLoad ? '1700ms' : '0ms')};

    @media ${devices.mobileMin} {
      width: 3000px;
      height: 2000px;
      transform: rotateZ(25deg) translateX(-50%);
      background: linear-gradient(
        215deg,
        rgba(0, 83, 159, 1) 50%,
        rgba(255, 255, 255, 0) 85%
      );
    }
  }
`;

const BigLinkBox = styled.li`
  grid-column: 1/3;
  justify-self: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
`;

const BigLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.black};
  font-size: 0.875em;
  line-height: 1em;
  padding: 22px 20px;
  height: 76px;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out;

  span {
    font-family: Genera-Regular;
  }

  @media ${devices.mobileMin} {
    justify-content: space-around;
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background-color: ${colors['blue-rgba-one']};
  }

  &:active {
    background-color: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const SmallLinks = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.black};
  font-size: 0.875em;
  line-height: 1em;
  padding: 10px 0;
  height: 76px;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out;

  span {
    display: inline-block;
    margin-top: 8px;
    font-family: Genera-Regular;
  }

  @media ${devices.mobileMin} {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding: 22px 20px;

    span {
      margin-top: 0px;
      font-family: Genera-Regular;
    }
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-one']};
    }
  }

  &:focus-visible {
    background-color: ${colors['blue-rgba-one']};
  }

  &:active {
    background-color: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const AboutLink = styled(Link)`
  color: ${colors.black};

  @media ${devices.nonTouchable} {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus-visible,
  &:active {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }
`;

const StartPage = () => {
  const { fuldaLogo, hlzLogo, vonderauLogo } = useStaticLogos();

  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem('InitialLoad') === null) {
      sessionStorage.setItem('InitialLoad', 'true');
      setIsInitialLoad(true);
    }
    setIsLoading(false);
  }, [isInitialLoad, isLoading]);

  const windowHeight = useWindowsHeight();

  if (isLoading) return '';
  return (
    <Box position="relative">
      {isInitialLoad ? (
        <LogoScreen aria-hidden="true" windowHeight={windowHeight}>
          <LogoContainer>
            <GatsbyImage
              image={vonderauLogo.childImageSharp.gatsbyImageData}
              alt="logo Vonderau Museum"
            />
            <GatsbyImage
              image={hlzLogo.childImageSharp.gatsbyImageData}
              alt="logo HLZ"
            />
            <GatsbyImage
              image={fuldaLogo.childImageSharp.gatsbyImageData}
              alt="logo Fulda"
            />
          </LogoContainer>
        </LogoScreen>
      ) : (
        ''
      )}
      <StartPageWrapper isInitialLoad={isInitialLoad}>
        <Flex
          as="section"
          height={`${windowHeight}px`}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="15px 20px"
          maxWidth={['500px', '680px']}
          marginX="auto"
          position="relative"
          aria-labelledby="section-header"
          zIndex="3"
        >
          <Box
            as="header"
            width="100%"
            marginBottom="40px"
            textAlign="center"
            color={colors.white}
          >
            <h1 id="section-header" className="homepage-title">
              Die Hessische Verfassung
            </h1>
          </Box>
          <Box as="nav" width="100%" aria-labelledby="nav-header">
            <p id="nav-header" className="sr-only">
              Hauptnavigation
            </p>
            <Grid
              as="ul"
              gridTemplateColumns="1fr 1fr"
              gridGap={[5, 6]}
              width="100%"
            >
              <BigLinkBox as="li">
                <BigLink to="/list">
                  <span>Die Hessische Verfassung</span>
                  <Constitution width="23" height="30" aria-hidden="true" />
                </BigLink>
              </BigLinkBox>
              <Flex
                as="li"
                justifyContent="center"
                background="rgba(255, 255, 255, 0.9)"
                borderRadius="8px"
              >
                <SmallLinks to="/lexikon">
                  <Books width="32" height="25" aria-hidden="true" />
                  <span>Lexikon</span>
                </SmallLinks>
              </Flex>
              <Flex
                as="li"
                justifyContent="center"
                background="rgba(255, 255, 255, 0.9)"
                borderRadius="8px"
              >
                <SmallLinks to="/suche">
                  <Search width="27" height="27" aria-hidden="true" />
                  <span>Suche</span>
                </SmallLinks>
              </Flex>
              <Flex
                as="li"
                justifyContent="center"
                background="rgba(255, 255, 255, 0.9)"
                borderRadius="8px"
              >
                <SmallLinks to="/quiz">
                  <Quiz width="30" height="33" aria-hidden="true" />
                  <span>Quiz</span>
                </SmallLinks>
              </Flex>
              <Flex
                as="li"
                justifyContent="center"
                background="rgba(255, 255, 255, 0.9)"
                borderRadius="8px"
              >
                <SmallLinks to="/hintergrund">
                  <InfoTap width="36" height="36" aria-hidden="true" />
                  <span>Hintergrund</span>
                </SmallLinks>
              </Flex>
            </Grid>
          </Box>
          <Flex
            mt="auto"
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
            position="absolute"
            left="0"
            bottom="0"
            padding="15px 20px"
          >
            <LogoContainer className="logo-container-small">
              <GatsbyImage
                image={vonderauLogo.childImageSharp.gatsbyImageData}
                alt="logo Vonderau"
              />
            </LogoContainer>
            <AboutLink to="/impressum">Impressum</AboutLink>
          </Flex>
        </Flex>
      </StartPageWrapper>
    </Box>
  );
};

export default StartPage;
