import { graphql, useStaticQuery } from 'gatsby';

const useLogos = () => {
  const data = useStaticQuery(graphql`
    query {
      fuldaLogo: file(relativePath: { eq: "fulda-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      hlzLogo: file(relativePath: { eq: "hlz-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      vonderauLogo: file(relativePath: { eq: "vonderau-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      dachmarkeLogo: file(relativePath: { eq: "dachmarke-logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      dmLogo: file(relativePath: { eq: "dm-logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
      dhLogo: file(relativePath: { eq: "dh-logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);

  return data;
};

export default useLogos;
