import { useState, useEffect } from 'react';

const useWindowsHeight = () => {
  const [windowHeight, setWindowHeight] = useState('100vh');

  const checkScreenSize = () => {
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return windowHeight;
};

export default useWindowsHeight;
