import Layout from '../components/Layout';
import StartPage from '../components/StartPage';
import React from 'react';

const IndexPage = () => {
  return (
    <Layout>
      <StartPage />
    </Layout>
  );
};

export default IndexPage;
